/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import { 
    Alert,
    Badge,
    CloseButton, 
    Form,
    Button,
    Card,
    InputGroup,
    Image,
    Stack,
    Container,
    FloatingLabel,
    Row,
    Col,
    Tab,
    Tabs,
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import * as Config from '../data/Config';

export default function User ( props ){

    let { userId } = useParams();
    const { authtoken, username } = props

    const [isLoading, setIsLoading] = useState(false);
    const [changedUser, setChangedUser] = useState(false);
    const [postUserSuccess, setPostUserSuccess] = useState(false);

    const [user, setUser] = useState(null);
    
    const [isMyself, setIsMyself] = useState(false);
    const [fio, setFio] = useState('');
    const [comment, setСomment] = useState('');
    const [equipment, setEquipment] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userfiles, setUserfiles] = useState([]);
    const [facsimile, setFacsimile] = useState('')


    const fileInput = useRef(null)
    const navigate = useNavigate();

    
    useEffect(() => {
        setIsLoading(true)
        API.Get({ method:'getuser', authtoken, userId})
        .then(({data}) => {
            console.log(data)
            if ( data.result ){
                setUser(data.user)
                setIsMyself( username == data.user.username )
                const {fio, facsimile, comment, equipment, userfiles, is_active, is_admin} = data.user
                setFio(fio)
                setFacsimile(facsimile)
                setСomment(comment)
                setEquipment(equipment)
                setUserfiles( isJsonString(userfiles) ? JSON.parse(userfiles) : [] )
                setIsActive( +is_active!=0 )
                setIsAdmin( +is_admin!=0 )
            }
        })
        .finally(()=>setIsLoading(false))
        
        API.Get({ method:'getuserfiles', authtoken, userId})
        .then(res => {
            // console.log( data )
        })
    }, [postUserSuccess])

    useEffect(() => {
        setChangedUser( user && (user.fio!=fio || user.comment!=comment || user.equipment!=equipment || (+user.is_active!=0)!=isActive  || (+user.is_admin!=0)!=isAdmin ) )
    }, [fio,comment,equipment,isActive, isAdmin])

    const postUser = () => {
        setIsLoading(true)
        API.Post({ method:'postuser', authtoken}, {userId, fio, comment, equipment, userfiles: JSON.stringify(userfiles), is_active: isActive ? 1:0, is_admin: isAdmin ? 1:0 })
        .then(res => {
            console.log( res.data )
            if (res.data.result){
                setPostUserSuccess( true )
                setChangedUser( false )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const postUserFiles = (userfiles) => {
        setIsLoading(true)
        API.Post({ method:'postuserfiles', authtoken}, {userId, userfiles: JSON.stringify(userfiles) })
        .then(res => {
            console.log( res.data )
            if (res.data.result){
                // 
            }
        })
        .finally(()=>setIsLoading(false))
    }

    
    // const [file, setFile] = useState()
    
    const uploadUserFile = ( file ) => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('userid', userId)
        formData.append('file', file)
        formData.append('filename', Date.now()+'.'+file.name.match(/\.([^.]+)$|$/)[1]) // change any file name to timestamp.png|jpg
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        API.Post({ method:'uploaduserfile', authtoken}, formData)
        .then(res => {
            const {result, files} = res.data
            if (result){
                setUserfiles( isJsonString(files) ? JSON.parse(files) : [] )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const onSortEnd = (oldIndex, newIndex) => {
        setUserfiles((array) => {
            const sortedArray = arrayMove(array, oldIndex, newIndex)
            postUserFiles( sortedArray )
            return sortedArray
        });
    };

    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const removeUserFile = ( fileToRemove ) => {
        setIsLoading(true)
        const userfilesWithoutRemovedFile = userfiles.filter( file => (file!=fileToRemove ))
        API.Post({ method:'removeuserfile', authtoken}, {userId, userfiles: JSON.stringify(userfilesWithoutRemovedFile), filetoremove: fileToRemove })
        .then(res => {
            // console.log( res.data )
            if (res.data.result){
                setUserfiles(  userfilesWithoutRemovedFile )
                // 
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const [tab, setTab] = useState('home')

    const uploadAccountFile = ( file, type, onSuccess ) => {
        console.log( 1 )

        setIsLoading(true)
        const formData = new FormData();
        formData.append('userid', userId)
        formData.append('type', type)
        formData.append('file', file)
        formData.append('filename', Date.now()+'.'+file.name.match(/\.([^.]+)$|$/)[1]) // change any file name to timestamp.png|jpg
        const config = { header: { 'content-type': 'multipart/form-data' }};
        console.log( formData.values() )
        
        API.Post({ method:'uploaduserfile', authtoken}, formData)
        .then(({data}) => {
            console.log( data )
            const {result, files} = data
            if (files && onSuccess){
                onSuccess( isJsonString(files) ? JSON.parse(files) : files )
            }
        })
        .finally(()=>setIsLoading(false))
    }
    
    const fileInput_ = useRef(null)
    const FileUploaderUI = ( getter, setter, type, name) => {

        return (
            <Alert variant="light" style={{maxWidth:240}} className='me-3 mb-3'>
                <div className='text-center'>
                    { getter ? (
                        <Image src={Config.Domain+getter} rounded style={{maxWidth: 200, maxHeight: 200, height: 200}} className="mb-3 object-fit-contain"/>
                    ) : (
                        <div className='p-4' style={{height: 200}}>
                            <p className='h5'>{name}</p>
                            <p className='h5'>{getter}</p>
                            <p className='small'>Пока не загружен</p>
                        </div>
                    )}
                </div>
                <Form.Control 
                    className="d-none"
                    type="file" 
                    ref={fileInput_}
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e)=> {
                        uploadAccountFile( e.target.files[0], type, setter)
                    }}
                />

                <Button
                    variant="outline-dark"
                    disabled={isLoading}
                    onClick={ () => fileInput_.current.click() }
                    className="w-100"
                >
                    <div>{ getter ? "Заменить" : "Загрузить"} {name.toLocaleLowerCase()}</div>
                    <div className='small'><div className='small'>.jpg .png .webp</div></div>
                </Button>
            </Alert>
        )
    }


    return (
        <>
            
            <Button variant="light" onClick={()=>navigate(-2)} className='mb-2'>&nbsp;&nbsp;&larr;&nbsp;&nbsp;</Button>
            <h1>{user?.fio}</h1>
            
            { +user?.is_active != 0 ? ( null ) : ( <Badge bg="secondary" className='h6'>Доступ заблокирован</Badge> )}
            
            
            {
                user ? (
                    <>
                        <Stack gap={1}>
                            <div><strong>{user.username}</strong></div>
                            <div>Добавлен: {user.date_insert.replace('T', ' ')}</div>
                            <div>Последний вход: {user.last_login.replace('T', ' ')}</div>
                        </Stack>
                        
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={tab}
                            onSelect={(k) => setTab(k)}
                            className="mb-3 mt-4"
                        >
                            <Tab eventKey="home" title="Настройки пользователя" className='p-2 mb-3'>
                                <Container className='ms-0 ps-0'>
                                    <Row>
                                        <Col md="10" sm>

                                                      
                                            <Form.Check // prettier-ignore
                                                type="checkbox"
                                                id="custom-switch"
                                                label={ isActive ? "Доступ разрешен" : "Доступ заблокирован" } 
                                                className='mb-3'
                                                checked={isActive}
                                                disabled={isMyself}
                                                onChange={(e)=> setIsActive(e.target.checked) }
                                            />
                                            
                                            <Form.Check 
                                                type="checkbox"
                                                id="custom-switch"
                                                label="Доступ а панель управления"
                                                className='mb-3'
                                                checked={isAdmin}
                                                disabled={isMyself}
                                                onChange={(e)=> setIsAdmin(e.target.checked) }
                                            />
                                            
                                            <FloatingLabel label="ФИО" className='mb-3'>
                                                <Form.Control
                                                    id="fio" 
                                                    type="text" 
                                                    value={fio} 
                                                    placeholder="ФИО" 
                                                    onChange={(e)=> setFio(e.target.value) }
                                                />
                                            </FloatingLabel>

                                            <FloatingLabel label="Оборудование специалиста" className='mb-4'>
                                                <Form.Control 
                                                    as="textarea"
                                                    rows={8}
                                                    value={equipment} 
                                                    placeholder="Например, Лазерный нивелир ADA CUBE 3-360"
                                                    onChange={(e)=> setEquipment(e.target.value) }
                                                    style={{height: 200}}
                                                />
                                                <Form.Text muted className='ms-3'>
                                                        Отображается в Акте осмотра и в Заключении по приёмкам от этого специалиста
                                                </Form.Text>
                                            </FloatingLabel>

                                            <FloatingLabel label="Комментарий администратора" className='mb-3'>
                                                <Form.Control 
                                                    id="comment"
                                                    type="text" 
                                                    value={comment} 
                                                    placeholder="Например, специализируется на ПИК" 
                                                    onChange={(e)=> setСomment(e.target.value) }
                                                />
                                            </FloatingLabel>

                                            <Button
                                                variant="primary"
                                                disabled={!changedUser}
                                                onClick={ postUser }
                                            >
                                                Сохранить
                                            </Button>

                                            {
                                                postUserSuccess && !changedUser ? (
                                                    <Alert className="mt-3" key={"postUserSuccess"} variant={"success"}>
                                                        Сохранено успешно
                                                    </Alert>
                                                ) : null
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>

                            <Tab eventKey="facsimile" title="Факсимиле">
                                <div className='d-flex flex-row'>
                                    { FileUploaderUI( facsimile, setFacsimile, 'facsimile', 'Факсимиле') }
                                </div>
                            </Tab>

                            <Tab eventKey="profile" title="Изображения для отчета" className='p-2'>
                                <Card className="text-center">
                                    <Card.Body>
                                        <Card.Text className='m-4 small'>
                                            Загрузите здесь сканы документов касающиеся конкретного специалиста, например, его личные сертификаты или сертификаты его оборудования. Эти документы будут приложены ко всем отчетам проведенных им приёмок.
                                        </Card.Text>
                                        
                                        <Form.Control 
                                            className="d-none"
                                            type="file" 
                                            ref={fileInput}
                                            accept="image/png, image/jpeg, image/jpg"
                                            onChange={ (e)=> uploadUserFile(e.target.files[0]) }
                                        />

                                        <Button
                                            className="mb-3"
                                            variant="primary"
                                            onClick={ () => fileInput.current.click() }
                                            disabled={isLoading}
                                        >
                                            Загрузить скан (.jpg, .png)
                                        </Button>
                                    </Card.Body>

                                    <Card.Footer className="text-muted p-3">
                                        {
                                            userfiles.length>0 ? (
                                                <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                                                    {userfiles.map((file) => (
                                                        <SortableItem key={file} style={{display: 'grid'}}>
                                                            <div className="d-inline-flex" style={{cursor: 'grab'}}>
                                                                <Image src={Config.Domain+file} rounded style={{maxHeight: 200}} className="mb-3"/>
                                                                <CloseButton className="me-3 align-top" onClick={()=>removeUserFile(file)}/>
                                                            </div>
                                                            
                                                        </SortableItem>
                                                    ))}
                                                </SortableList>
                                            ) : 'Ни одно изображение не загружено'
                                        }
                                    </Card.Footer>
                                </Card>
                            </Tab>
                        </Tabs>
                    </>
                ) : null
            }
        </>
    )
}