/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import { 
    Alert,
    Accordion, 
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';



export default function SettingsForm ( props ){
    const {authtoken} = props

    const [isLoading, setIsLoading] = useState(false)
    
    const [plans, setPlans] = useState([])
    const [activePlan, setActivePlan] = useState({id:0})

    useEffect(() => {

    },[])
    

    return(
        <>
            <h1>Помощь</h1>

            <div className="row">
                <div className="col-xl-12 col-md-6">
                        <Alert variant="secondary" className='mb-3'>
                            Приложение запущено в тестовом режиме, при выяалении ошибок, пишите на <a href="https://t.me/stumarkin">@stumarkin</a>
                        </Alert>
                </div>
            </div>

            <Accordion defaultActiveKey="0" style={{maxWidth: 700}}>
                
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Как установить приложение?</Accordion.Header>
                    <Accordion.Body>
                        Сейчас есть возможность установить приложение на Android из apk, для этого обратитесь к <a href="https://t.me/stumarkin">@stumarkin</a>.<br/>
                        В течении апреля приложения для всех платформ будут размещены в магазинах AppStore и Google Play
                    </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey="1">
                    <Accordion.Header>В прилоджении ошибка или оно вылетает?</Accordion.Header>
                    <Accordion.Body>
                        Пожалуйста братитесь к <a href="https://t.me/stumarkin">@stumarkin</a> прикрепив скриншот проблемы или видео экрана в момент возникновения ошибки.<br/>
                    </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey="2">
                    <Accordion.Header>После обновления структуры приёмки в приложении все сломалось</Accordion.Header>
                    <Accordion.Body>
                        Такое возможно, если вы не соблюли соответсвие кодов в таблице или использовали недопустимые спецсимволы или переносы строк. Пожалуйста перепроверьте это. Если проблему не удалось решить самостоятельно, обратитесь к <a href="https://t.me/stumarkin">@stumarkin</a>
                    </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Я индивидуальные специалист и у меня для акта нет печати и логотипа</Accordion.Header>
                    <Accordion.Body>
                        Вы можете загрузить пустую картинку (белый пиксель) и тогда в акте они не будут видны. Если проблему не удалось решить самостоятельно, обратитесь к <a href="https://t.me/stumarkin">@stumarkin</a>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>Что будет с более ранней версией приложения Приёмка? </Accordion.Header>
                    <Accordion.Body>
                        Первая версия приложения более не поддерживается. Вы можете перейти к использованию текущей версии, при необходимости мы можем перенести все ваши приёмки в новую очетную запись. Для этого обратитесь к <a href="https://t.me/stumarkin">@stumarkin</a>
                    </Accordion.Body>
                </Accordion.Item>
                
            </Accordion>

        </>

    )
}