/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import { 
    Table, 
    Button,
    ButtonGroup,
    Alert,
    InputGroup,
    Stack,
    Container,
    Row,
    Col,
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as Config from '../data/Config';


import "react-datepicker/dist/react-datepicker.css";


export default function Form (props){
    let { formId } = useParams();
    const {authtoken} = props


    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(null);
    const [dictionary, setDictionary] = useState(null);
    const navigate = useNavigate();

    
    useEffect(() => {
        setIsLoading(true)

        API.Get({ method:'getform', authtoken, formId})
        .then(res => {
            if ( res.data.result ){
                let form = res.data.form
                // console.log()
                form.form_json =  res.data.form.form 
                form.form = JSON.parse( res.data.form.form )
                setForm(form)
        
                API.Get({ method:'getdictionary', authtoken, version: form.form.formTemplateVersion})
                .then(res => {
                    const {result, dictionary } = res.data
                    console.log(form.form.formTemplateVersion);
                    console.log(dictionary);
                    if (result && dictionary){
                        console.log(dictionary)
                        setDictionary(dictionary)
                    }
                })

                
            }
        })
        .finally(()=>setIsLoading(false))

    },[])



    const getName = ( obj, showClause = false ) => {
        return obj.name || ( ( dictionary[ (obj.templateId || obj.id) ]?.report || dictionary[ (obj.templateId || obj.id) ]?.name || obj.id )
                              + ( obj.comment ? `; ${obj.comment}` : '' )
                              + ( showClause ? `. ${dictionary[ (obj.templateId ? obj.templateId : obj.id) ].clause}` : '' ) 
                            ) ;
    }

    const getFailChecks = ( form, showClause = false) => {
        let i = 1
        const failChecks = form?.apartment
        .map( (room) => (
        {
            ...room, 
            sections: room.nested.reduce( (sections, section ) => {
                        const checks = section.nested.reduce( (checks, check) => {
                        return checks += (!check.value ? `<br/>${i++}. ${getName(check, showClause)}` : '')
                        }, '' )
                        return sections += (checks!='' ? `<br/>${getName(section).toUpperCase()}:</h2>${checks}<br/>` : '')
                    }, '') +  (room.comment.length>0 ? `Другое:<br/>${i++} ${room.comment}<br/><hr/>` : '' )
        }
        ))
        .reduce( (sum, room) => ( sum += (room.sections!='' ? `<br/><h4>${room.name.toUpperCase()}</h4>${room.sections}` : '') ), '' ) 
      
        return failChecks ? `В результате осмотра квартиры по адресу ${form.address}, ${form.apartmentNum} ` +
                `выявлены следующие недостатки:<br/>`+
                `${failChecks}` : `В результате осмотра квартиры недостатки не выявлены`;
    }


    return (
        <>
            <Button variant="light" onClick={()=>navigate(-2)} className='mb-2'>&nbsp;&nbsp;&larr;&nbsp;&nbsp;</Button>
            <h1>{form?.address || 'Без адреса'}{form?.apartmentNum ? ", "+form?.apartmentNum : ""}</h1>
            
            <ol class="breadcrumb mb-4">
                <li class="breadcrumb-item active"></li>
            </ol>
            
            {
                form ? (
                    <Container>
                        
                         <Row>
                            <Col lg="5">
                                <div>Заказчик: {form.customer || 'не указан'}</div>
                                <div>Специалист: {form.fio}</div>
                                <div>Выявлено {form.failChecksCountTotal} недостатков из {form?.checksCountTotal} проверок</div>
                                <div>Дата проведения: {form.timestamp?.replace('T', ' ')}</div>
                                <div>{formId} / {form.form.token}</div>
                            
                            </Col>
                            <Col lg="4" className='d-flex justify-content-end align-items-end'>
                                <div>
                                    <ButtonGroup size="md" className="mb-2">
                                        <Button variant="primary" className="px-3" href={`${Config.Domain}/report/${formId}`} target='_blank'>Акт осмотра</Button>
                                        <Button variant="primary" className="px-3" href={`${Config.Domain}/report/${formId}?expert&token=${form.form.token}`} target='_blank'>✨Заключение</Button>
                                    </ButtonGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="9">
                                <div className="rounded p-5 mt-4" style={{backgroundColor: '#FAF0DC'}} dangerouslySetInnerHTML={{ __html: form && dictionary ? getFailChecks(form.form) : null }} />
                            </Col>
                        </Row>
                        
                    </Container>
                ) : null
            }
        </>
    )
}