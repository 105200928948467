/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import { 
    Alert,
    Badge,
    Table, 
    Form,
    FloatingLabel,
    InputGroup,
    Card,
    Container,
    Row,
    Col,
    Button
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { Link, useNavigate } from 'react-router-dom';


import "react-datepicker/dist/react-datepicker.css";


export default function Forms ( props ){
    const {authtoken} = props



    const [showAddUserForm, setShowAddUserForm] = useState(false);
    const [highlightId, setHighlightId] = useState(0);
    
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');

    const [username, setUsername] = useState( '' )
    const [fio, setFio] = useState( '' )

    const [userCountLimit, setUserCountLimit] = useState( 10 );

    const today = new Date()
    const prevDate = new Date()
    prevDate.setDate( today.getDate() - 30 )
    const [startDate, setStartDate] = useState( prevDate.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState( today.toISOString() );

    const [activePlan, setActivePlan] = useState({id:0, limit_users:0})


    const navigate = useNavigate();

    function isValidEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      }
      

    useEffect(() => {
        API.Get({ method:'getaccountplan', authtoken})
        .then(res => {
            const {result, plan} = res.data
            if (result && plan){
                setActivePlan( plan )
                setUserCountLimit( plan.limit_users )
            }
        })
    },[])
    
    useEffect(() => {
        GetUsers()
    },[search, startDate, endDate])

    const GetUsers = ( highlightId ) => {
        setIsLoading(true)
        API.Get({ method:'getusers', authtoken, search})
        .then(res => {
            setUsers( res.data.users ? res.data.users : [] );
        })
        .finally(()=>setIsLoading(false))
    }

    

    const AddUser = () => {
        if (!username||!fio){
            alert("Не указаны e-mail или ФИО")
        } else if (users.length >= userCountLimit) {
            alert("Исчерпан лимит добавления пользователей по тарифу")
        } else if (!isValidEmail(username)) {
            alert("Поверьте правильность введенного e-mail")
        } else {
            setIsLoading(true)
            API.Post({ method:'adduser', authtoken}, {username, fio})
            .then(res => {
                console.log( res.data )
                if (res.data.result){
                    setHighlightId( res.data.userid )
                    setUsername('')
                    setFio('')
                    setShowAddUserForm(false)
                    GetUsers()
                }
            })
            .finally(()=>setIsLoading(false))
        }
    }

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
    
        const padZero = (num) => (num < 10 ? '0' + num : num);
        const formattedTime = `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
    
        if (date.toDateString() === currentDate.toDateString()) {
            return 'Сегодня ' + formattedTime
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Вчера ' + formattedTime
        } else {
            return `${padZero(date.getDate())}&nbsp;${getMonthName(date.getMonth())} ${formattedTime}`
        }
    }
    
    const getMonthName = (month) => {
        const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        return months[month];
    }

    return (
        <>
            <h1>Пользователи</h1>

            {/* <div className="row">
                <div className="col-xl-12 col-md-6">
                        <Alert variant="secondary" className='mb-3'>
                            Приложение запущено в тестовом режиме, при выяалении ошибок, пишите на <a href="https://t.me/stumarkin">@stumarkin</a>
                        </Alert>
                </div>
            </div>

            {
                !activePlan.id ? (
                    <Alert variant="warning" style={{maxWidth:800}}>
                        Добавление новых специалистов доступно после подключения тарифа.
                    </Alert>
                ):null
            } */}
            
            {
                showAddUserForm ? (
                    <Card className="mb-4" style={{maxWidth: 500}}>
                        <Card.Header>
                            
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                            <FloatingLabel label="E-mail специалиста" className='mb-2'>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email"
                                    value={username}
                                    onChange={(e)=> setUsername(e.target.value) }
                                />
                            </FloatingLabel>
                            <FloatingLabel label="ФИО">
                                <Form.Control 
                                    type="text" 
                                    placeholder="Пароль"
                                    value={fio}
                                    onChange={(e)=> setFio(e.target.value) }
                                    onKeyDown={ (e) => e.key === 'Enter' ? AddUser() : null }
                                />
                            </FloatingLabel>
                            </Card.Text>
                            
                        
                            <Button
                                variant="primary"
                                disabled={isLoading || !(activePlan.limit_users - users.length)}
                                onClick={AddUser}
                                className='px-5'
                            >
                                Добавить
                            </Button>
                            <Button
                                variant="link"
                                disabled={ isLoading}
                                className='text-decoration-none text-secondary'
                                onClick={()=>setShowAddUserForm(false)}

                            >
                                Отмена
                            </Button>

                            <div className='mt-3'>
                                {
                                    !!(activePlan.limit_users - users.length) ? (
                                        <>Можно добавить еще {activePlan.limit_users - users.length} пользователей по тарифу {activePlan.name}</>
                                    ):(
                                        <div className="text-danger">Лимит исчерпан.<br/>Вы уже добавили максимальное кол-во пользователей по тарифу {activePlan.name}</div>
                                    )
                                }
                            </div>

                        </Card.Body>
                        
                    </Card>

                ) : (
                    <>
                        <Button
                            variant="primary"
                            disabled={isLoading || !activePlan.id}
                            onClick={()=>setShowAddUserForm(true)}
                            className='px-5'
                        >
                            Добавить пользователя
                        </Button>
                        <div className="small"></div>
                    </>

                )
            }

            <Card className="mt-2">
                <Card.Header>
                    <Container className='ms-0 ps-0'>
                        <Row>
                            <Col xl={6} md={12}>
                                <InputGroup>
                                    <InputGroup.Text>Поиск</InputGroup.Text>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="#, логин или ФИО" 
                                        onChange={(e)=> setSearch(e.target.value) }
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Card.Header>

                <Card.Body>
                    <div class={isLoading && 'opacity-50'}>
                        {
                            users.length > 0 ? (
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>ФИО</th>
                                            <th className='w-auto'>Логин</th>
                                            <th className='d-none d-sm-block'>Последний вход</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        users.map( ({id, username, fio, last_login, is_active, is_admin}) => (
                                            <tr className={id == highlightId ? 'table-success' : ( !+is_active ? 'opacity-50' : '')} onClick={(e)=> navigate(`/users/${id}`) } style={{cursor: 'pointer'}}>
                                                <td><Link to={`/users/${id}`}>{id}</Link></td>
                                                <td>{fio} {!+is_active ? <Badge bg="secondary" className='h6'>Заблокирован</Badge> : ''}</td>
                                                <td className='w-auto'>
                                                    {username}
                                                    {!+is_admin ? '' : (' ⭐️')}
                                                </td>
                                                <td className='d-none d-sm-block' dangerouslySetInnerHTML={{ __html: last_login ? formatDate(last_login) : '&nbsp;'}}></td>
                                            </tr>
                                        ) )
                                    }
                                    </tbody>
                                </Table>
                            ) : (
                                <div>По заданным параметрам пользователей не найдено.</div>
                            )
                        }
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}