export default function Footer (){
    return (
        <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                    {/* <div className="text-muted small"> <a href="https://priemka-pro.ru/" target="_blank">Приёмка Про</a> &middot; 2022-24 &middot; Сделано в России с ❤️ </div> */}
                    <div className="small">
                        Права защищены лицензией &copy; <a href="https://priemka-pro.ru/privacy.policy.priemka-pro.ru.html" target="_blank">Политика использования</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}